import React, { useContext, useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Container, Typography } from '@material-ui/core';

import SearchContext from '../context/SearchContext';
import { useFlexSearch } from '../hooks/useFlexSearch';

const StyledContainer = withStyles({
	root: {
		background: '#fff',
		boxShadow:
			'0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
		padding: '50px',
		borderRadius: '4px',
		width: '100%',
		marginTop: '1rem',
		color: '#19305A',
		transition: 'all 1s',
		'&:hover': {
			transform: 'scale(1.02)',
			transition: 'all 0.25s',
			cursor: 'pointer',
			boxShadow:
				'0 13px 27px -5px rgba(50, 50, 93, 0.5), 0 8px 16px -8px rgba(0, 0, 0, 0.6)',
		},
	},
})(Container);

const useStyles = makeStyles((theme) => ({
	icon: {
		border: `1px solid #F1F2F5`,
		background: '#F1F2F5',
		borderRadius: '15px',
		padding: '.5rem',
		maxHeight: '68px',
	},
}));

const SearchResults = ({
	data: {
		results: { index, store },
	},
}) => {
	const classes = useStyles();
	const [isSearchLoading, setIsSearchLoading] = useState(true);
	const { searchQuery, setSearchQuery } = useContext(SearchContext);
	const { isLoading, rawResults } = useFlexSearch(searchQuery, index, store);

	useEffect(() => {
		setIsSearchLoading(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (!isSearchLoading && !rawResults.length) {
			setSearchQuery('pest');
		}
	}, [isSearchLoading, rawResults]);

	if (!searchQuery) {
		return (
			<Container style={{ minHeight: '70vh' }}>
				<Grid container direction='row' justify='center' alignItems='center'>
					<Typography variant='h6' style={{ marginTop: '5rem' }}>
						Please enter a search term
					</Typography>
				</Grid>
			</Container>
		);
	}

	const handleClick = (e, slug, affiliateLink) => {
		e.preventDefault();
    !affiliateLink ? navigate(`/${slug}`) : window.open(`${affiliateLink}`, '_blank');
	};

	return (
		!isSearchLoading && (
			<Container style={{ minHeight: '70vh', marginBottom: '2rem' }}>
				<Typography
					variant='h6'
					style={{ marginTop: '3rem', marginBottom: '1rem' }}>
					{searchQuery !== 'pest'
						? `${rawResults.length} results displayed for ${searchQuery}`
						: `Nothing matched your search. Here are some of our most popular products.`}
				</Typography>
				{rawResults.length &&
					rawResults.map((result) => (
						<StyledContainer onClick={(e) => handleClick(e, result.slug, result.affiliateLink)}>
							<Grid
								container
								direction='row'
								alignItems='center'
								justify='flex-start'
								spacing={2}>
								<Grid item>
									{result.wwProductLogo || result.type === 'partner' ? (
										result.wwProductLogo?.filterLogo.asset?.gatsbyImageData
											.images.sources.length ? (
											<img
												src={result.wwProductLogo.filterLogo.asset.url}
												alt='chart'
												imgStyle={{ objectFit: 'contain' }}
												style={{ height: '125px', width: '125px' }}
											/>
										) : (
											<GatsbyImage
												image={
													result.type === 'addon'
														? result.wwProductLogo?.filterLogo?.asset
																?.gatsbyImageData
														: result.filterIcon.asset.gatsbyImageData
												}
												alt='ww-product-logo'
												style={{ maxWidth: '125px', maxHeight: '125px' }}
											/>
										)
									) : (
										<div className={classes.icon}>
											<FontAwesomeIcon
												icon={['fad', result.filterIcon]}
												style={{ height: '50px', width: '50px' }}
											/>
										</div>
									)}
								</Grid>
								<Grid item>
									<Typography variant='h5'>{result.title}</Typography>
									<Typography variant='body1'>{result.body}</Typography>
									<Typography variant='body1'>
										<b>Available for:</b>{' '}
										{result.products.map((product, index) =>
											index < result.products.length - 1
												? `${product.title}, `
												: `${product.title}`
										)}
									</Typography>
								</Grid>
							</Grid>
						</StyledContainer>
					))}
			</Container>
		)
	);
};

export const query = graphql`
	query SearchQuery {
		results: localSearchResults {
			index
			store
		}
	}
`;

export default SearchResults;
